.scoutComparablesCard {
  background: var(--color-background-light);

  .cardTitleContainer {
    width: 65%;
  }

  .cardContent {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .mapContainer {
    height: 85%;
    width: 100%;
    @media (max-width: 767px) {
      height: 300px;
    }
  }

  .table-responsive {
    height: 325px;
    border: none;

    &::-webkit-scrollbar {
      width: 0.66rem;
      height: 1rem;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--color-linen);
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-walnut);
      border-radius: 2px;
    }
  }

  tr {
    td {
      border-width: 1px;
      font-size: 14px;
      padding: 6px 8px;
      background: hsl(38.3, 40.17%, 98%);
    }
  }

  thead {
    position: sticky;
    top: -1px;
    tr {
      th {
        border-width: 0;
        background: white;
        font-size: 14px;
        font-weight: 700 !important;
      }
      .spacerColumn {
        min-width: 4px;
        max-width: 4px;
        padding: 0;
        background: var(--color-linen-white);
      }
    }
  }
}

table.table > tbody > tr:hover td,
table.table > tbody > tr:hover th {
  background-color: var(--color-goldwood-light) !important;
  font-weight: 500;
}

.scoutComparablesCardTitle {
  color: hsl(195.68, 44.9%, 40%);
  font-weight: 700;
}

.comparableAddress {
  min-width: 285px;
}

.comparableWeight {
  max-width: 50px;
}
.compStatsPillContainer {
  max-width: 120px;
}

.compStatsPillLabel {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

#comparablesMap {
  width: 100%;
  height: 100%;
}
