.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item.active {
  background-color: #71b2c9 !important;
}

/* TO DO: this should probably be global in bootstrap theming */
.btn:focus {
  box-shadow: none !important;
}

.userDropDownWrapper {
  position: relative;
}
.userDropDown {
  background-color: transparent !important;
  border: none !important;
}
.userDropDownDiv {
  z-index: 100;
  position: absolute !important;
  width: 300px !important;
  transform: translate(-12px, 38px) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #71b2c9 !important;
}
