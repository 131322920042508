.nav-button {
  border-color: transparent !important;
  background: transparent !important;
  position: absolute;
  left: -2px;
  padding: 2px !important;
}

.nav-button:hover,
.nav-button:active {
  background: var(--color-background-light) !important;
}

.page-content {
  width: calc(100% - 6rem);
  margin-left: 6rem;
  transition: all 0.4s;
  position: relative;
}

#content.active {
  width: 100%;
  margin: 0;
}
