.auth-wrapper [data-amplify-authenticator] [data-amplify-container]{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
@media  (max-width: 767px) {
    .auth-wrapper [data-amplify-authenticator] [data-amplify-container]{
        left: 50%;
        transform: translate(-50%,-50%);
        width: 85%;
    }
}