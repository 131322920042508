.mapPropCard {
  background: var(--color-background-light);
  transition: all ease 0.5s;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);


  .propInfo {
    font-size: 0.85rem;
  }

  .mapPropAddress {
    color: var(--color-charcoal);
  }

  .property-logo {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
  }

  .mapPropEstimatedSpread {
    color: var(--color-skyfall);
    font-size: 0.85rem;
    line-height: 0;
    @media (max-width: 992px) {
      line-height: 1.2rem;
    }
  }
}

.mapPropCard:hover {
  margin-left: 10px;
}
