[data-loader] {
    margin: 80px;
  }
  
  [data-loader='logo-circle'] {
    width: 120px;
    height: 120px;
    -webkit-animation: circle infinite .75s linear;
    -moz-animation: circle infinite .75s linear;
    -o-animation: circle infinite .75s linear;
    animation: circle infinite .75s linear;
    border: 4px solid #3b3535;
    border-top-color: transparent;
    border-radius: 100%;
    display: block;
    margin: auto;
  }
  
  @-webkit-keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @-o-keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }