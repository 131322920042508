.scoutDashboardRow {
  min-height: 200px;
  margin-top: 0px !important;
}

.scoutDashboardCard {
  background: var(--color-background-light);
  line-height: 115%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.scoutDashboardCardTitle {
  color: hsl(195.68, 44.9%, 40%);
  font-weight: 700;
}

.productFits {
  color: var(--color-aro-green);
}

.productNoFit {
  color: var(--color-aro-red);
}

.fitUnknown {
  font-weight: 500;
}

.lotFitValue {
  max-height: 20px;
  overflow: hidden;
}
