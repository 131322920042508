.PFButton {
  width: 92px;
  padding-right: 6px;
  padding-left: 6px;
  color: white;
  font-weight: 600;
}

.PFButton:hover,
.PFButton:active,
.PFButton:focus-within {
  transform: scale(1.1);
}

.PFDropDownLabel {
  font-size: 14px;
  line-height: 18px;
}
