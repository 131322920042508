.verticalNav {
  min-width: 6rem;
  width: 6rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.sidebarLink:hover,
.sidebarLink:active {
  transform: scale(1.01);
}

.highlight {
  background: var(--color-skyfall-white);
}

#sidebar.inActive {
  margin-left: -6rem;
}

.endOptionsContainer {
  position: fixed;
  bottom: 20px; 
  left: 15px;
}

.termsOfUseLink {
    color: #3d3935;
    font-weight: 500;
    font-size: 14px;
    display: block;
    max-width: 50px;
    line-height: 15px;
}