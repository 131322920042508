.scoutBuyboxCalculator {
  background: var(--color-background-light);
  max-width: 1140px;
  @media (max-width: 992px) {
    width: 75%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: auto;
  }

  .flex-basis-100 {
    flex-basis: 100%;
  }
  .flex-basis-33 {
    flex-basis: 33%;
  }
  .flex-basis-66 {
    flex-basis: 66%;
  }
  
  .buyboxSection {
    background: hsl(38.3, 40.17%, 98%); 
    border-color: rgb(222, 226, 230);
  }

  .buyboxSubsection {
    height: 230px;
    @media (max-width: 992px) {
      height: auto;
    }
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .inputHolder {
    background: #fff;
    border: 1px solid #767676;
    display: inline-block;
    padding: 2px 5px;
  }
  .inputHolder > input {
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
  }
  .inputHolder:after {
    content: "%";
  }
}
