.googleMap a,
.appleMap a,
.zillowIcon a {
  text-decoration: none;
}

.appleMap a,
.zillowIcon a {
  margin-left: 20px;
}

.googleMap svg {
  margin-top: -8px;
}

.appleMap svg {
  margin-top: -10px;
}

.zillowIcon svg {
  width: 24px;
  margin-top: -10px;
}

.scoutAddressWidth {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zillowIcon svg:hover path,
.appleMap svg:hover path,
.googleMap svg:hover path {
  fill: var(--color-goldwood);
  transition: all 0.3s;
}

@media (max-width: 480px) and (orientation: portrait) {
  .googleMap a {
    margin-left: 0px;
  }
  .appleMap a {
    margin-left: 5px;
  }
  .googleMap i,
  .appleMap i {
    font-size: 20px;
  }
  .scoutAddressWidth {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .zillowIcon svg {
    width: 17px;
    margin-left: 10px;
    margin-top: -7px;
  }
  .scoreTitle {
    padding-top: 3px;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .googleMap a {
    margin-left: 10px;
  }
  .appleMap a {
    margin-left: 10px;
  }
  .googleMap i,
  .appleMap i {
    font-size: 22px;
  }
  .zillowIcon svg {
    width: 17px;
    margin-left: 8px;
    margin-top: -7px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .googleMap a {
    margin-left: 0px;
  }
  .appleMap a {
    margin-left: 10px;
  }
}
