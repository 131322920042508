#scoutMap {
  width: 100%;
  height: 100%;
}

.viabilityOKBorder {
  border: 4px solid var(--color-aro-green);
}
.viabilityFailBorder {
  border: 4px solid var(--color-aro-red);
}
.viabilityUnknownBorder {
  border: 4px solid var(--color-aro-gray);
}

.viabilityPassResult {
  color: var(--color-aro-green);
}
.viabilityNoPassResult {
  color: var(--color-aro-red);
}
.viabilityConcernsResult {
  color: var(--color-aro-yellow-dark);
}
.viabilityNAResult {
  color: var(--color-charcoal);
}

.goodSpreadStyle {
  color: var(--color-aro-green);
}

.smallSpreadStyle {
  color: var(--color-aro-red);
}

.characteristicsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 0.5rem;
  row-gap: 1rem;
}

.sharepointLink {
  color: var(--color-skyfall-dark);
}
.sharepointLink:hover,
.sharepointLink:active,
.sharepointLink:focus-within {
  color: var(--color-skyfall);
  svg path {
    fill: var(--color-skyfall);
  }
}

@media (max-width: 991px) {
  #scoutMap {
    min-height: 220px;
  }
}
