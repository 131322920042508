.staticDropDown {
  background: var(--color-linen-white);
  font-weight: 500;
}

.staticDropDownList .dropDownSelectMenu {
  width: 90%;
  font-weight: 500;
}

.staticDropDownItem.active,
.staticDropDownItem:active {
  background-color: var(--color-linen-white) !important;
  color: var(--color-charcoal) !important;
}
