.scoutTooltipContainer {
  background: var(--color-skyfall-light);
}

.nextChartButton {
  right: 0;
  bottom: 0;
  border: 1px solid var(--color-goldwood-light) !important;
}

.nextChartButton:hover {
  background: var(--color-goldwood) !important;
  svg path {
    fill: white;
  }
}

.referenceLineText {
  font-size: 8px;
  fill: var(--color-skyfall);
}
