.map {
  height: calc(100vh - 90px);
  @media (max-width: 767px) {
    height: 70vh;
  }
}

.maplibregl-popup-content {
  padding: 5px 10px !important;
}

.scotPopUpButton {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 20px;
}
.scotPopUpButton a {
  color: #3d3935;
}
.scotPopUpButton a:hover {
  color: #71b2c9;
}

.scotPopUpButton button {
  border: none;
  width: 60px;
  height: 25px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #8c857b;
  color: #fff;
}
.scotPopUpButton button a {
  color: #fff;
  text-decoration: none;
}
canvas.maplibregl-canvas.mapboxgl-canvas {
  cursor: context-menu;
}
.scoutAddres {
  font-size: 16px;
  font-weight: bold;
}

.mapboxgl-popup-tip {
  display: block !important;
}

.maplibregl-popup {
  top: -20px !important;
}
.mapboxgl-popup,
.maplibregl-popup {
  z-index: 3;
}

@media (max-width: 480px) and (orientation: portrait) {
  .map {
    height: calc(100vh - 45vh);
  }
}
@media (max-width: 786px) and (orientation: landscape) {
  .map {
    height: calc(100vh - 35vh);
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .maplibregl-popup {
    height: 174px;
  }
  .maplibregl-popup-content {
    height: 174px;
  }
  .popupInnerWrapper {
    overflow-y: scroll;
    height: 160px;
  }
  .maplibregl-popup-content {
    padding: 10px 10px !important;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .maplibregl-popup {
    height: 120px;
  }
  .maplibregl-popup-content {
    height: 120px;
  }
  .popupInnerWrapper {
    overflow-y: scroll;
    height: 110px;
  }
}
