.inner-btn {
  position: absolute;
  width: 10px;
  font-weight: 550;
  border: none;
  background-color: transparent;
  line-height: 35px;
  left: calc(100% - 90px);
  z-index: 100;
}

.inner-btn:hover {
  color: blue;
}
