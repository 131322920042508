.kanbanPropCard {
  transition: background-color 0.2s ease;
  background: var(--color-background-medium);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.kanbanPropCardDragging {
  transition: background-color 0.2s ease;
  background: var(--color-linen-dark);
  min-width: 278px;
}

.propSource {
  font-size: 0.85rem;
  line-height: 1rem;
  color: var(--color-skyfall-dark);
}

.propInfo {
  font-size: 0.85rem;
}

.propAddress {
  color: var(--color-charcoal);
}

.propEstimatedSpread {
  color: var(--color-skyfall);
}

.notFeasibleButton {
  background: var(--color-goldwood) !important;
  color: white !important;
}
