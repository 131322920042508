.dropDownButton {
  width: 100%;
  position: relative;
  svg {
    margin-top: -4px;
  }
}

.dropDownLight button {
  background: #fff;
}

.dropDownDark button {
  background-color: var(--color-skyfall) !important;
  color: white;
}

.dropDownLight .dropDownButton:hover,
.dropDownLight .dropDownButton:active,
.dropDownLight .dropDownButton:focus-within {
  background-color: var(--color-skyfall) !important;
  color: white;
  svg path {
    fill: white;
  }
}

.dropDownDark {
  svg path {
    fill: white;
  }
}

.dropDownDark .dropDownButton:hover,
.dropDownDark .dropDownButton:active,
.dropDownDark .dropDownButton:focus-within {
  background-color: var(--color-skyfall-dark) !important;
  color: white;
  svg path {
    fill: white;
  }
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropDownDark .dropdown-item.active {
  background-color: var(--color-skyfall-dark) !important;
}

.dropDownLight .dropdown-item.active {
  background-color: var(--color-skyfall) !important;
}

.btn:focus {
  box-shadow: none !important;
}

.dropDownSelectMenu {
  width: 90%;
  text-align: center !important;
}

.dropDownDark .dropdown-item.active,
.dropDownDark .dropdown-item:active {
  background-color: var(--color-skyfall-dark) !important;
}

.dropDownLight .dropdown-item.active,
.dropDownLight .dropdown-item:active {
  background-color: var(--color-skyfall) !important;
}

@media (max-width: 480px) and (orientation: portrait) {
  .dropDownSelectMenu {
    width: 93%;
    text-align: center !important;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .dropDownButton {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .dropDownButton {
    font-size: 12px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1223px) {
  .dropDownButton {
    font-size: 18px !important;
  }
}
