.pill {
  border-radius: 8px;
  padding: 16px 16px;
  text-align: center;
  color: white;
  font-weight: 500;
}

.pill-linen {
  background: var(--color-linen);
}

.pill-linen-dark {
  background: var(--color-linen-dark);
}

.pill-compact {
  padding: 8px 6px;
  font-size: 14px;
}
