.viabilityPill {
  border-radius: 12px;
  color: white;
  font-weight: 500;
  max-width: 68px;
  max-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viabilityPillUnknown {
  background: var(--color-aro-gray);
}

.viabilityPillOK {
  background: var(--color-aro-green);
}

.viabilityPillFail {
  background: var(--color-aro-red);
}
