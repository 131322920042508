.map-overlay-property-wrapper {
  padding-bottom: 10px;
  overflow-y: scroll;
  overflow: auto;
  max-height: calc(100vh - 90px);
}
.map-overlay-property-wrapper::-webkit-scrollbar {
  width: 1px;
}

@media (max-width: 480px) and (orientation: portrait) {
  .map-overlay-property-wrapper {
    height: auto;
    overflow: unset;
    max-height: 100%;
  }
}
